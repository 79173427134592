'use client';

import { useState, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { useHydrated } from 'react-hydration-provider';
import Image from 'next/image';

import { Icon, InfoModal, Logo } from 'components';
import { LEFT_MENU_ITEMS } from 'components/header/constants';
import { useTranslation } from 'i18n/client';
import { userAPI } from 'api';
import { IMenuItem } from 'components/header/types';
import { IInfoModalData, IRoute } from 'common/types';
import { getSession } from 'core';
import { constants, routesConstants, _ } from 'common';
import playStoreImage from 'common/images/play-store.svg';
import googlePlayImage from 'common/images/google-play.svg';
import monriImage from 'common/images/monri-web.svg';
import visaImage from 'common/images/visa.svg';
import masterCardImage from 'common/images/mastercard.svg';
import maestroImage from 'common/images/maestro.svg';
import jcbImage from 'common/images/jcb.svg';
import americanExpressImage from 'common/images/american-express.svg';
import dinersImage from 'common/images/diners.svg';
import discoverImage from 'common/images/discover.svg';

interface IProps {
    lang: string;
}

const { getUserDetailsAPI } = userAPI;
const { routes, prefixes } = routesConstants;

const Footer = ({ lang }: IProps) => {
    const hydrated = useHydrated();
    const { t } = useTranslation(lang, 'footer');
    const pathname = usePathname();
    const session = getSession();
    const isUserLoggedIn = session && session?.accessToken ? true : false;
    const userDetails = session?.userDetails;
    const [modalData, setModalData] = useState<IInfoModalData>({ isOpen: false, title: '', message: '' });

    const handleOpenRules = async () => {
        const path = routes[prefixes.terms].path;

        const newTab = window.open(path, '_blank');

        if (newTab) {
            newTab.focus();
        }
    };

    const handleGoToBanners = async () => {
        try {
            if (isUserLoggedIn) {
                const response = await getUserDetailsAPI();
                const details = response?.data || {};
                const path = `/${lang}${routes[prefixes.kyc].path}/${userDetails?.uuid || ''}`;

                if (details.verificationStatus === constants.USER_STATUSES.initial.value) {
                    const goToKyc = () => {
                        window.open(path, '_blank', 'noopener,noreferrer');
                    };

                    setModalData({
                        isOpen: true,
                        title: t('START_VERIFICATION_TITLE'),
                        message: t('START_VERIFICATION_MESSAGE'),
                        buttonText: t('START_VERIFICATION_BUTTON'),
                        handleButtonClick: goToKyc,
                    });
                    return;
                }

                if (details.verificationStatus === constants.USER_STATUSES.pending.value) {
                    setModalData({ isOpen: true, title: t('PENDING_VERIFICATION_TITLE'), message: t('PENDING_VERIFICATION_MESSAGE') });
                    return;
                }

                if (details.verificationStatus === constants.USER_STATUSES.declined.value) {
                    setModalData({
                        isOpen: true,
                        title: t('DECLINED_VERIFICATION_TITLE'),
                        message: t('DECLINED_VERIFICATION_MESSAGE'),
                        buttonText: t('DECLINED_VERIFICATION_BUTTON'),
                        handleButtonClick: () => {
                            window.open(path, '_blank', 'noopener,noreferrer');
                        },
                    });
                    return;
                }

                window.open(`/${lang}${routes[prefixes.banners].path}`, '_blank', 'noopener,noreferrer');
            } else {
                window.open(`/${lang}${routes[prefixes.login].path}`, '_blank', 'noopener,noreferrer');
            }
        } catch (error) {
            console.log('Error redirecting to the banners page.');
        }
    };

    const shouldRenderFooter = useMemo(() => {
        let appRoutes: any = Object.assign({}, routes);
        delete appRoutes[prefixes.home];
        appRoutes = _.values(appRoutes);

        const selectedRoute: IRoute = pathname === `/${lang}` ? routes[prefixes.home] : appRoutes.find((item: IRoute) => pathname.indexOf(item.path) > -1);

        return selectedRoute.show_footer || false;
    }, [pathname, lang]);

    if (shouldRenderFooter && hydrated) {
        return (
            <div className="mt-40 bg-grey-800 px-16 md:px-[0px]">
                <InfoModal {...modalData} handleClose={() => setModalData({ isOpen: false, title: '', message: '' })} />
                <div className="container mx-[auto]">
                    <div className="py-40">
                        <div className="block md:flex items-center">
                            <div className="mr-32 mb-16 md:mb-[0px]">
                                <Logo lang={lang} />
                            </div>
                            <div className="flex items-center mb-16 md:mb-[0px]">
                                {LEFT_MENU_ITEMS.map((item: IMenuItem, index: number) => {
                                    if (item.isRules) {
                                        return (
                                            <p key={`left-menu-item-${index}`} onClick={handleOpenRules} className="mr-32 text-sm-title-light font-normal text-grey-300 cursor-pointer">
                                                {t(item.label)}
                                            </p>
                                        );
                                    }

                                    if (!item.path || (item.isProtected && !isUserLoggedIn)) {
                                        return null;
                                    }

                                    if (item.isBanners) {
                                        return (
                                            <p key={`left-menu-item-${index}`} onClick={handleGoToBanners} className="mr-32 text-sm-title-light font-normal text-grey-300 cursor-pointer">
                                                {t(item.label)}
                                            </p>
                                        );
                                    }

                                    return (
                                        <a
                                            key={`left-menu-item-${index}`}
                                            href={`/${lang}${item.path}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="mr-32 text-sm-title-light font-normal text-grey-300"
                                        >
                                            {t(item.label)}
                                        </a>
                                    );
                                })}
                            </div>
                            <div className="flex items-center ml-[auto]">
                                <a href={constants.PLAY_STORE_LINK} target="_blank" className="mr-[2px] md:mr-16">
                                    <Image src={playStoreImage} alt="play-store-image" fill objectFit="cover" className="!relative !w-[auto] !h-[54px]" />
                                </a>
                                <a href={constants.GOOGLE_PLAY_LINK} target="_blank">
                                    <Image src={googlePlayImage} alt="google-play-image" fill objectFit="cover" className="!relative !w-[auto] !h-[54px]" />
                                </a>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 mt-32 gap-y-16 md:gap-y-[0px] gap-x-16 max-w-full md:max-w-full lg:max-w-[80%]">
                            <div className="w-fit">
                                <p className="text-sm-title font-normal text-grey-300 mb-12">{t('CONTACT_PHONE')}</p>
                                <p className="text-[20px] text-black-100">{'+387 65 40 20 20'}</p>
                            </div>
                            <div className="w-fit">
                                <p className="text-sm-title font-normal text-grey-300 mb-12">{t('CUSTOMER_SUPPORT')}</p>
                                <a href={`mailto:${constants.SUPPORT_EMAIL}`} className="text-[20px] text-black-100">
                                    {'podrska@naklik.ba'}
                                </a>
                            </div>
                            <div className="w-fit">
                                <p className="text-sm-title font-normal text-grey-300 mb-12">{t('ADDRESS')}</p>
                                <a href="https://maps.app.goo.gl/SfDRLGtKc9bGJZe49" target="_blank" className="text-[20px] text-black-100">
                                    {'Novice Cerovića 34, Banja Luka'}
                                </a>
                            </div>
                        </div>

                        <div className="flex flex-wrap items-center mt-32 gap-8">
                            <Image src={monriImage} alt="monri-image" fill objectFit="cover" className="!relative !w-[auto] !h-[54px]" />
                            <Image src={visaImage} alt="visa-image" fill objectFit="cover" className="!relative !w-[auto] !h-[50px]" />
                            <Image src={masterCardImage} alt="mastercard-image" fill objectFit="cover" className="!relative !w-[auto] !h-[50px]" />
                            <Image src={maestroImage} alt="maestro-image" fill objectFit="cover" className="!relative !w-[auto] !h-[50px]" />
                            <Image src={jcbImage} alt="jcb-image" fill objectFit="cover" className="!relative !w-[auto] !h-[50px]" />
                            <Image src={americanExpressImage} alt="american-express-image" fill objectFit="cover" className="!relative !w-[auto] !h-[50px]" />
                            <Image src={dinersImage} alt="diners-image" fill objectFit="cover" className="!relative !w-[auto] !h-[50px]" />
                            <Image src={discoverImage} alt="discover-image" fill objectFit="cover" className="!relative !w-[auto] !h-[50px]" />
                        </div>

                        <div className="block md:flex items-center mt-32">
                            <div className="flex items-center mb-16 md:mb-[0px]">
                                <a href={constants.FACEBOOK} target="_blank">
                                    <div className="flex items-center justify-start rounded-[50%] p-[10px] border border-grey-400 mr-[20px]">
                                        <Icon name="facebook-1" className="w-[16px] h-[16px]" />
                                    </div>
                                </a>
                                <a href={constants.INSTAGRAM} target="_blank">
                                    <div className="flex items-center justify-start rounded-[50%] p-[10px] border border-grey-400 mr-[20px]">
                                        <Icon name="instagram-1" className="w-[16px] h-[16px]" />
                                    </div>
                                </a>
                                <a href={constants.TIK_TOK} target="_blank">
                                    <div className="flex items-center justify-start rounded-[50%] p-[10px] border border-grey-400 mr-[20px]">
                                        <Icon name="tik-tok" className="w-[16px] h-[16px]" />
                                    </div>
                                </a>
                                <a href={constants.TWITTER} target="_blank">
                                    <div className="flex items-center justify-start rounded-[50%] p-[10px] border border-grey-400">
                                        <Icon name="twitter" className="w-[16px] h-[16px]" />
                                    </div>
                                </a>
                            </div>
                            <div className="ml-[auto] text-xsm-title text-grey-300 font-normal">
                                <span>&copy;</span>
                                <span>{` ${new Date().getFullYear()}`}</span>
                                <span>{` - ${t('COPYRIGHT')}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default Footer;
